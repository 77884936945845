<template>
	<div class="camera-container text-center" style="cursor: pointer" @click="startMobilePwaCapture">
		<CameraIcon v-if="!useButton" color="#E2E6EB" :size="25" fill="#06263E" />
		<b-button v-if="useButton" variant="outline-primary"> <i class="icon-camera"></i> {{ labelButton }} </b-button>

    
		<CropCaptureModal
			ref="myCropPicture"
			v-bind="$props"
			v-model="isCropModalOpen"
      :base-url-img.sync="currentImg"
			@cropped="handleAutoCrop"
      @close="isCropModalOpen = false"
		/>
		<!-- <b-modal -->
		<!-- 	header-class="header-class-modal-doc-package" -->
		<!-- 	v-if="$has(currentImg)" -->
		<!-- 	id="mobile-profile-pic-capture-btn--modal" -->
		<!-- 	ref="modal" -->
		<!-- 	v-model="isCropModalOpen" -->
		<!-- 	ok-variant="success" -->
		<!-- 	size="xl" -->
		<!-- 	:title="FormMSG(5, 'Crop picture')" -->
		<!-- 	:ok-title="FormMSG(201, 'Save')" -->
		<!-- 	@ok="handleSavePicture" -->
		<!-- > -->
		<!-- 	<CropPicture -->
		<!-- 		ref="myCropPicture" -->
		<!-- 		:key="instanceToken" -->
		<!-- 		is-pwa-capture -->
		<!-- 		:type="cropType" -->
		<!-- 		:has-validation-button="false" -->
		<!-- 		:base-url-img="currentImg" -->
		<!-- 		@cropped="handleAutoCrop" -->
		<!-- 	/> -->
		<!---->
		<!-- 	<template #modal-footer="{ ok, cancel }"> -->
		<!-- 		<div class="w-100 btn-footer-modal"> -->
		<!-- 			<b-button variant="outline-danger" @click="cancel()"> -->
		<!-- 				{{ FormMSG(67896, 'Cancel') }} -->
		<!-- 			</b-button> -->
		<!-- 			<b-button :disabled="isStartMobilePwaCaptureLoading" variant="primary" class="w-100" @click="ok()"> -->
		<!-- 				<b-spinner v-show="isStartMobilePwaCaptureLoading" small /> -->
		<!-- 				<span>{{ FormMSG(201, 'Save') }}</span> -->
		<!-- 			</b-button> -->
		<!-- 		</div> -->
		<!-- 	</template> -->
		<!-- </b-modal> -->
	</div>
</template>

<script>
import { Camera as CameraIcon } from 'lucide-vue';
import { Camera, CameraResultType } from '@capacitor/camera';

import { isNil, makeID } from '~utils';
import mapProps from '@/shared/vuePropsMapper';
import { store } from '@/store';
import { b64toFile } from '@/components/CaptureModule/capture.utils';
import languageMessages from '@/mixins/languageMessages';

// import CropPicture from '@/components/CropPicture';
import CropCaptureModal from '@/components/Packages/Captures/components/CropCaptureModal';

export default {
	name: 'ProfilePicCaptureBtnCaptureModuleComponent',
	components: { CameraIcon, CropCaptureModal },
	mixins: [languageMessages],
	props: {
		...mapProps(['parentType', 'parentSubType', 'labelButton'], {
			type: String,
			required: false,
			default: null
			// validator: v => authorizedParentsTypes.includes(v)
		}),
		parentId: {
			type: [Number, String],
			required: false,
			default: null
		},
		useButton: {
			type: Boolean,
			default: false
		},
		cropType: {
			type: String,
			default: 'default',
			required: false,
			validator: (v) => ['avatar', 'default'].includes(v)
		}
	},
	data() {
		return {
			isStartMobilePwaCaptureLoading: false,
			instanceToken: makeID(5),
			currentImg: null,
			croppedImg: null,
			isCropModalOpen: false,
			isCropOpen: false
		};
	},
	methods: {
		async startMobilePwaCapture() {
			this.$emit('click');
			// this.isStartMobilePwaCaptureLoading = true
			store.state.capturedImage = '';
			store.state.imageCropped = '';

			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				allowEditing: false,
				quality: 100,
				// width: 400,
				// height: 400
			});

			this.currentImg = { img: photo.webPath };
			this.isCropModalOpen = true;
		},

		async saveImg() {
			if (isNil(this.croppedImg)) return;
			const base64Data = this.croppedImg.split(';base64,');
			const ext = base64Data[0].replace('data:image/', '');

			const file = b64toFile(base64Data[1], `profile-picture.${ext}`, ext);
			let formData = new FormData();

			formData.append('uploadimage', file);

			if (!isNil(this.parentType)) formData.append('parentType', this.parentType);
			if (!isNil(this.parentSubType)) formData.append('parentSubType', this.parentSubType);
			if (!isNil(this.parentId)) formData.append('parentId', this.parentId);

			try {
				const savedImg = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.$emit('change', [savedImg]);
			} catch (e) {
				this.$toast({
					message: this.FormMSG(1225, 'Update image issue'),
					title: this.FormMSG(368, 'Error'),
					variant: 'danger'
				});
			}
		},

		async handleSavePicture() {
			this.isStartMobilePwaCaptureLoading = true;
			await this.saveImg();
			this.isStartMobilePwaCaptureLoading = false;
			this.isCropModalOpen = false;
		},

		/**
		 * @param {Object} cropPayload
		 */
		handleAutoCrop(cropPayload) {
      console.log({ handleAutoCrop: cropPayload })
			this.croppedImg = cropPayload.img;
      this.handleSavePicture();
		}
	}
};
</script>

<style lang="scss" scoped>
.btn-footer-modal {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	.btn:first-child {
		margin-right: 15px;
	}
}
</style>
